import { useSearchApi } from '~/api/search'
import { IGameItem } from '~/types/common'

export const useSearch = () => {
  const { SEARCH } = useSearchApi()
  const { $axios } = useNuxtApp()
  const { isLoading, load, loading } = useLoading()
  const router = useRouter()
  const route = useRoute()

  const KEYWORD_POPULAR_GAME = 'Game thịnh hành'
  const LIMIT: number = 20

  const isSearched = ref(false)
  const page = ref<number>(1)
  const search = ref<string>('')
  const suggestedKeywords = ref<string[]>([])
  const recentlySearch = ref<string[]>([])
  const resultGames = ref<IGameItem[]>([])
  const totalGame = ref<number>(0)
  const recentlyGames = ref<IGameItem[]>([])

  const isLoadMore = computed(() => {
    return resultGames.value.length < totalGame.value
  })

  const updateQuery = (searchValue: any) => {
    if (route.query.search === searchValue) {
      return
    }
    const updatedSearch = formatQuery(searchValue)
    router.replace({ query: { ...route.query, search: updatedSearch } })
  }

  const formatQuery = (str: string): string => {
    const withoutDiacritics = str
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
      .replace(/đ/g, 'd')
      .replace(/Đ/g, 'D')

    return withoutDiacritics.replace(/ /g, '-').toLowerCase()
  }

  const removeQuery = () => {
    router.replace({ query: { ...route.query, search: undefined } })
  }

  const searchGame = async ($state: any | undefined = undefined) => {
    try {
      if ($state) {
        page.value = ++page.value
      }
      loading()
      const { data: response } = await $axios.$get(SEARCH(search.value, page.value, LIMIT))

      suggestedKeywords.value = [KEYWORD_POPULAR_GAME]
      if (response?.list_trendy_game || response?.list_trendy_casino) {
        suggestedKeywords.value = [
          ...suggestedKeywords.value,
          ...(response?.list_trendy_game || []),
          ...(response?.list_trendy_casino || [])
        ]
      }
      if (response.total_game) {
        resultGames.value = [...resultGames.value, ...response.list_game]
      } else {
        resultGames.value = []
      }

      if (response?.list_keyword.length) {
        recentlySearch.value = response.list_keyword.map((item: { keyword: any }) => item.keyword).slice(0, 5)
      }

      totalGame.value = response.total_game || resultGames.value.length
      recentlyGames.value = response.recently
      if (search.value) {
        isSearched.value = true
      }
      updateQuery(search.value)

      if ($state && response.list_game?.length < LIMIT) {
        $state?.complete()
      } else if ($state) {
        $state?.loaded()
      }
    } catch (error) {
      console.log(error)
    } finally {
      load()
    }
  }

  const resetSearch = () => {
    page.value = 1
    resultGames.value = []
    totalGame.value = 0
  }

  return {
    isLoading,
    isLoadMore,
    search,
    page,
    totalGame,
    recentlySearch,
    recentlyGames,
    suggestedKeywords,
    resultGames,
    searchGame,
    resetSearch,
    isSearched,
    updateQuery,
    removeQuery,
    formatQuery
  }
}
